/** 
 * Author: Hemant Sharma
 * Type: Service 
 * Objective: Serve common functionality accross applicaion 
 * Associated Route/Usage: Global 
*/ 

import authService from "./authService";
import config from "../config";
import messages from "../utils/data/messages";
import { toast } from 'react-toastify';
import _ from 'underscore';
import frontendChannel from "src/streams/frontendChannel";

const commonService = {
   newRestCall,
   clone,
   getFormattedDate,
   changeDateFormateReadable,
   objToParams,
   getURLRoute,
   getData,
   setData,
   removeData
};

/**
   * Used to Construct HTTP headers
   * @customHeaders : NULL || JSON Object
   * @returns : Object
*/
function getNewJsonHeaders(customHeaders){
  
   let headers = new Headers();
   headers.append("Content-Type", "application/json");
   headers.append("Access-Control-Allow-Headers", "Authorization");
   headers.append("Access-Control-Allow-Origin", "*");
   headers.append("mode", "no-cors");

   /** Setting up custom headers */
   if(customHeaders){
      Object.values(customHeaders).forEach((value) => {
         var el = Object.keys(value);
         for(var i=0; i<=el.length;i++){
            headers.append(el[i], value[el]);
         }
      });
   }
   
   /** Setting up JWT token into the headers */
   if(localStorage.getItem('user-token')){
      let userToken = JSON.parse(localStorage.getItem('user-token'));
      headers.append('x-access-token', userToken);
   }
   
   /** Setting up other token into the headers */
   if(sessionStorage.getItem('critical-token')){
      headers.append('y-access-token', sessionStorage.getItem('critical-token'));
   }
   return headers;
}

/**
   * REST response handler
   * @response : xhr object
   * @returns : JSON Object
*/
function newHandleResponse(response) {
      return response.text().then(text => {
         const data = text && JSON.parse(text);
         if(response.status===200 || response.status === 430){ // Handling the Successfull response 
            return data?({
               status : response.status,
               data
            }):response;
         } else if(response.status === 401){ // Handling the Unauthorized user
            authService.clear();
            if(window.location.pathname !== "/") //if not home page
               window.location = config.routes.login;
            return response;
         } else if(response.status === 400) {   // Handling the Bad requests
            toast.error(data.message?data.message:messages.common.badRequest);
            return response;
         } 
         else if(response.status === 426 || response.status === 209 || response.status===205) {
            frontendChannel.postMessage("refresh");
            return response;
         } 
         else if(response.status === 500) { // Handling the server side Exceptions
            toast.error(data.message?data.message:messages.common.unavailable);
            if(data){
               return ({
                  status : response.status,
                  data
               });
            }else{
               return response;
            }
         } else{  // Handling the other responses
            return data?({
               status : response.status,
               message : (data.data && data.data.length > 0)?data.data[0].message : data.message,
               data : data
            }):response;
         }
     }).catch((error) => { // Handling the Exceptions
         toast.error(error.message?error.message:messages.common.down);
      });
}

/**
   * Used for common REST call
   * @options : JSON Object (properties needed for REST call)
   * @returns : JSON Object
*/
function newRestCall(options, addHeaders=true) {
   let windowObj = window.location.pathname.split("/");
   if(windowObj && windowObj.length > 0) {
      let checkKeyExist = windowObj.filter((ele) => {return ele === "auth" || ele === "ForgotPassword" || ele === "ResetPassword" 
         || ele === "AccountVerify" || ele === "Signup" || ele === "SendVerification"}
      )
      if(checkKeyExist.length === 0){
         authService.throwBack();
      }
   }
   const requestOptions = {
       method: options.method,
       body: JSON.stringify(options.data),
       redirect: 'follow'
   };

   if(addHeaders){
      requestOptions.headers = getNewJsonHeaders(options.headers);
   }

   return fetch(options.url,requestOptions)
   .then(newHandleResponse)
   .then((data) =>  {
     return data;
   }).catch((error) => {
      toast.error(error.message?error.message:messages.common.down);
      return error;
   });;
}

/**
   * Used to Deep clone object
   * @obj  : obj 
   * @returns : JSON Object
*/
function clone(obj){
   return JSON.parse(JSON.stringify(obj));
}

/**
   * Used to get the Time from a Date object
   * @date  : Date Object
   * @isAmPm  : Boolean
   * @returns : String
*/
function getNewTime(date, isAmPm = false){
   let ampm = '';
   let hours = date.getHours();
   let actualHours = hours;
   hours = hours % 12;
   hours = hours ? hours : 12; // the hour '0' should be '12'
   hours = hours < 10 ? '0'+hours : hours;

   let minutes = date.getMinutes();
   minutes = minutes < 10 ? '0'+minutes : minutes;

   ampm = hours + ':' + minutes;
   if(isAmPm){
      ampm = actualHours >= 12 ? ampm + ' PM' : ampm + ' AM';
   }

   return ampm;
}

/**
   * Used to get the Day from a Date object
   * @date  : Date Object
   * @returns : String
*/
function getNewDay(date){
   let day  = date.getDate().toString();
   day = day.length > 1 ? day : '0' + day;
   return day;
}

/**
   * Used to get the Month from a Date object
   * @date  : Date Object
   * @returns : String
*/
function getNewMonth(date){
   let month = (1 + date.getMonth()).toString();
   month = month.length > 1 ? month : '0' + month;
   return month;
}

/**
   * Used to get the formated date string from a Date object
   * @date  : Date Object
   * @format  : String
   * @dateOnly  : Boolean
   * @returns : String
*/
function getFormattedDate(date, format, dateOnly) {
   var fdate ="";
   const monthsArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
   switch(format){
      case 'yyyymmdd':
         fdate = [
            date.getFullYear(),
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2)
          ].join('/');
      break;

      case 'ddmmyyyy': 
         fdate = getNewMonth(date) + '/' + getNewDay(date) +  '/' + date.getFullYear();
         if(!dateOnly) fdate+=" " + getNewTime(date, true);
      break;

      case 'dMY':
         fdate = getNewDay(date) + ' ' + monthsArr[date.getMonth()] +  ' ' + date.getFullYear();
      break;   

      case 'hm': 
         let hours = date.getHours();
         hours = hours < 10 ? '0'+hours : hours;
         let minutes = date.getMinutes();
         minutes = minutes < 10 ? '0'+minutes : minutes;
         fdate = hours + ':' + minutes;
      break; 

      case 'msa': 
         fdate = getNewTime(date, true);
      break;

      case 'dMYmsa':
         fdate = getNewDay(date) + ' ' + monthsArr[date.getMonth()] +  ' ' + date.getFullYear() + ' ' + getNewTime(date, true);
      break;   

      default :
         fdate = date.getFullYear() + '-' + getNewMonth(date) + '-' + getNewDay(date);
   }
   return fdate;
}

/**
   * Used to change date string into a readable format
   * @d  : String (A valid date)
   * @returns : String
*/
function changeDateFormateReadable(d) {
   const fulldays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

   const aDate = new Date(d);
   const bDate = new Date();
   const diffDays = bDate.getDate() - aDate.getDate();
   const diffMonths = bDate.getMonth() - aDate.getMonth();
   const diffYears = bDate.getFullYear() - aDate.getFullYear();

   if(diffYears === 0 && diffDays === 0 && diffMonths === 0){
      return "Today";
   } else if(diffYears === 0 && diffMonths === 0 && diffDays === 1) {
      return "Yesterday";
   } else if(diffYears === 0 && diffMonths === 0 && diffDays === -1) {
      return "Tomorrow";
   } else if(diffYears === 0 && diffMonths === 0 && diffDays < 7) {
      return fulldays[aDate.getDay()];
   } else {
      return getFormattedDate(aDate, 'dMY');
   }
}

/**
   * Use to constructs query params from an object 
   * @obj   : object 
   * @returns : String 
*/ 
function objToParams( obj ) {
   let str = '?' + Object.keys(obj).reduce(function(a, k){
       a.push(k + '=' + encodeURIComponent(obj[k]));
       return a;
   }, []).join('&');
   return str;
}

/**
   * Use to get the current route
   * @void 
   * @returns : String 
*/ 
function getURLRoute(){
   var route = window.location.pathname.replace('#','');
   route = route.split('/');
   route  = route.length>3?route.slice(0, -1).join('/'):route.join('/');
   return route;
}

/**
   * Use to get the data from the browser local storage via a key
   * @key : String 
   * @isRaw : Boolean 
   * @returns : String || JSON Object 
*/ 
function getData(key, isRaw){
   try{
      return isRaw?localStorage.getItem(key):JSON.parse(localStorage.getItem(key));
   }catch(e){}
}

/**
   * Use to set the data into the browser local storage via a key
   * @key : String 
   * @data : String || JSON Object  
   * @isRaw : Boolean 
   * @returns : Boolean 
*/ 
function setData(key,data,isRaw){
   try{
      return localStorage.setItem(key, isRaw?data:JSON.stringify(data));
   }catch(e){}
}


/**
   * Use to remove the data into the browser local storage via a key
   * @key : String 
   * @returns : Void 
*/ 
function removeData(keys){
   try{
      if(_.isArray(keys)){
         _.map(keys, function(key){ 
            localStorage.removeItem(key);
          }); 
      }else{
         localStorage.removeItem(keys);
      }
      
   }catch(e){}
}

export default commonService;
