/** 
 * Author: Hemant Sharma
 * Type: Service 
 * Objective: Serve common functionality for user management 
 * Associated Route/Usage: "#/user-management" 
*/ 

import config from '../config';
import commonService from './commonService';

const service = {
    removeUser,
    listOfOrganizationUsers,
    listOfRequests,
    updateUserRole,
    acceptRequests,
    rejectRequests,
    listOfInvitationsSent,
    resendInvitation,
    inviteNewUser,
    getCountOfRequestsForRoleChangeInOrg,
    getUserDetails,
    getProjectsInOrganization,
    getUserProjectRequests
};

export default service;

/**
   * Used to remove an user from Organization
   * @data : JSON Object
   * @returns : Promise Object
*/
function removeUser(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/org/remove-user`,
        method : 'DELETE',
        data
    });
}

/**
   * Used to fetch all the users of current organization
   * @page_num : number
   * @returns : Promise Object
*/
function listOfOrganizationUsers(page_num) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/org/all-users-of-org/${ page_num }`,
        method : 'GET'
    });
}

/**
   * Used to fetch all the requests of current organization
   * @page_num : number
   * @returns : Promise Object
*/
function listOfRequests(page_num) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/role/role-change-requests/${ page_num }`,
        method : 'GET'
    });
}

/**
   * Used to change the users role for the current Organization
   * @data : JSON Object
   * @returns : Promise Object
*/
function updateUserRole(data) {
    return commonService.newRestCall({
        url: `${config.apiUrl.node}/role/role-change`,
        method:'PUT',
        data
    });
}

/**
   * Used to approving the users pending request for the current Organization
   * @data : JSON Object
   * @returns : Promise Object
*/
function acceptRequests(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/role/accept-role-change-request`,
        method:'PUT',
        data
    });
}

/**
   * Used to rejecting the users pending request for the current Organization
   * @data : JSON Object
   * @returns : Promise Object
*/
function rejectRequests(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/role/reject-role-change-request`,
        method:'PUT',
        data
    });
}

/**
   * Used to fetching the list of sent invitations for the current Organization
   * @page_num : number
   * @returns : Promise Object
*/
function listOfInvitationsSent(page_num) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/invitation/sent-invitations/${ page_num }`,
        method : 'GET'
    });
}

/**
   * Used to send an invite to a user for the current Organization
   * @data : JSON Object
   * @returns : Promise Object
*/
function inviteNewUser(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/invitation/send-invitation`,
        method : 'POST',
        data:data
    });
}

/**
   * Used to resend the invite to a user for the current Organization
   * @data : JSON Object
   * @returns : Promise Object
*/
function resendInvitation(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/invitation/resend-invitation`,
        method : 'POST',
        data:data
    });
}

/**
   * Used to get count of requests for role change in organization
   * @returns : Promise Object
*/
function getCountOfRequestsForRoleChangeInOrg() {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/role/pending-role-change-request-count`,
        method : 'GET'
    });
}

/**
   * Used to get user details
   * @returns : Promise Object
*/
function getUserDetails(org_tenant_id) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/org/tenant-details/${org_tenant_id}`,
        method : 'GET'
    });
}

/**
   * Used to get projects in organization
   * @returns : Promise Object
*/
function getProjectsInOrganization(org_tenant_id) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/project/tenant-projects/${org_tenant_id}`,
        method : 'GET'
    });
}

/**
   * Used to get user project requests
   * @returns : Promise Object
*/
function getUserProjectRequests(org_tenant_id) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/project/user-project-requests/${org_tenant_id}`,
        method : 'GET'
    });
}