/**
 * Author: Hemant Sharma
 * Type: Configuration
 * Objective: To access global variables accross application
 * Usage: Accross application -65.0.80.82
*/

var configuration = {
    appName: "AONFLOW-ReactUI",
    apiUrl: (window.location.hostname === 'localhost') ? {
        node: 'http://localhost:4000/api/v1',
        socket: 'http://localhost:4000'
    } : {
        node: '/api/v1',
        socket: `https://${window.location.hostname}`
    },
    routes: {
        home: "/",
        afterLogin: "/home",
        userOrgs: "/home/accounts",
        login: '/',
        addOrg: '/auth/new-org',
        ifInvites: '/auth/invitations',
        forgotPass: '/auth/forgot-password',
        projects: '/home/projects',
        flows: '/home/flows',
        settings: '/home/settings'
    },
    cryptKey: "Hemant@Aug-08",
    loaderTime: 1000,   //ms,
    blogsApiUrl: 'https://www.aonflow.com/blog/wp-json/wp/v2/posts',
    termsAndConditions: "https://www.aonflow.com/terms-of-use",
    privacyPolicy: "https://www.aonflow.com/privacy-policy"
}


module.exports = configuration;