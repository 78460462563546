/**
 * Author: Hemant Sharma
 * Type: Component
 * Objective: To render App
 * Associated Route/Usage: Root
 */

import React, { useEffect, useContext } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import authService from "./services/authService";
import frontendChannel from "src/streams/frontendChannel";
import AonContext from "src/context/aonContext";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import theme from "./muiStyles/theme";
import CssBaseline from '@mui/material/CssBaseline';

// Layouts
const InnerLayout = React.lazy(() => import("./views/layouts/InnerLayout"));

const FullScreenContent = React.lazy(() => import("./containers/FullScreenContent"));

// Pages
const Page404 = React.lazy(() => import("./views/errors/Page404"));
const Page500 = React.lazy(() => import("./views/errors/Page500"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  const { showReloadPopup } = useContext(AonContext);

  useEffect(() => {
    frontendChannel.onmessage = (msg) => {
      if (msg === "refresh") {
        showReloadPopup();
      } else if (msg === "Logout") {
        authService.logout();
      }
    };
  }, []);

  return (
    <CssVarsProvider theme={theme}>
       <CssBaseline />
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Routes>
            <Route
              path="auth/*"
              name="auth"
              element={<FullScreenContent />}
            />
            <Route
              path="home/*"
              name="Index"
              element={<InnerLayout />}
            />
            <Route
              path="500"
              name="Page 500"
              element={<Page500 />}
            />
            <Route
              path="/*"
              name="Landing Page"
              element={<FullScreenContent />}
            />
            <Route
              path="*"
              name="Page 404"
              element={<Page404 />}
            />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </CssVarsProvider>
  );
};

export default App;