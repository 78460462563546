/** 
 * Author: Hemant Sharma 
 * Type: Service 
 * Objective: Serve common functionality for auth 
*/ 

import config from '../config';
import commonService from './commonService';
import frontendChannel from 'src/streams/frontendChannel';

const authService = {
    logout,
    reloadPage,
    accountVerify,
    signUpService,
    resendVerificationLink,
    getInvitationsByMail,
    getInvitationsCount,
    getCurrentTenant,
    clear,
    hasLoggedIn,
    throwBack,
    getUser,
    getFirstName,
    setIsUserHaveOrg,
    getIsUserHaveOrg
};

export default authService;

/**
   * Used to verify User account 
   * @token : string
   * @email : string (A valid email)
   * @returns : Promise Object
*/
function accountVerify(token, email) {
    return commonService.newRestCall({
        url: `${config.apiUrl.node}/user/account-verified/${token}/${email}`,
        method:'GET'
    });
}

/**
   * Used to create User's new account
   * @data : JSON object
   * @returns : Promise Object
*/
function signUpService(data) {
    return commonService.newRestCall({
        url: `${config.apiUrl.node}/user/signup`,
        method: 'POST',
        data: data
    });
}

/**
   * Used to resend verification link via email
   * @email : string
   * @returns : Promise Object
*/
function resendVerificationLink(email) {
    return commonService.newRestCall({
        url: `${config.apiUrl.node}/user/resend-verify-link/${email}`,
        method: 'GET'
    });
}

/**
   * Used to fetch all invitations to the user
   * @status : string
   * @returns : Promise Object
*/
function getInvitationsByMail(status) {
    return commonService.newRestCall({
        url: `${config.apiUrl.node}/invitation/get-invitations?status=${status}`,
        method: 'GET'
    });
}

/**
   * Get count of invitations to the user
   * @void
   * @returns : Promise Object
*/
function getInvitationsCount() {
    return commonService.newRestCall({
        url: `${config.apiUrl.node}/invitation/get-pending-invitation-count`,
        method: 'GET'
    });
}

/**
   * Get current organization of the user
   * @void
   * @returns : Promise Object
*/
function getCurrentTenant() {
    return commonService.newRestCall({
        url: `${config.apiUrl.node}/user/get-current-tenant`,
        method: 'GET'
    });
}

/**
   * User logout method, removes all user details from browser localstorage and reloads page
   * @void
   * @returns : Void
*/
function logout() {
    frontendChannel.postMessage("Logout");
    localStorage.removeItem('user');
    localStorage.removeItem('user-token');
    localStorage.removeItem('isUserHaveOrg');
    window.location = config.routes.home;
}

/**
   * just reloading the page
   * @void
   * @returns : Void
*/
function reloadPage() {
    //frontendChannel.postMessage("refresh");
    window.location.reload();
}

/**
   * Removes user details from browser localstorage
   * @void
   * @returns : Void
*/
function clear() {
    localStorage.removeItem('user');    // removing the user details
    localStorage.removeItem('user-token'); // removing the JWT token
    localStorage.removeItem('isUserHaveOrg'); //removing the flag for user's Organization
    localStorage.removeItem("current-org"); // removing the user current-organization data
}

/**
   * Returns flag from browser localstorage if the user is already logged in
   * @void
   * @returns : Boolean
*/
function hasLoggedIn(){
  return (localStorage.getItem('user-token') && localStorage.getItem('user'))?true:false;
}

/**
   * Removes user object and JWT Token from browser localstorage and redirects to login page
   * @void
   * @returns : Void
*/
function throwBack(){
    if(!localStorage.getItem('user-token') || !localStorage.getItem('user')){
        window.location = config.routes.login;
    }
}
  
/**
   * get the user details from browser localstorage
   * @void
   * @returns : String || Object
*/
function getUser(){
    if(localStorage.getItem('user'))
        return JSON.parse(localStorage.getItem('user'));
    return null;    
}

/**
   * to extract the user first name
   * @name : string
   * @returns : String
*/
function getFirstName(name){
    let lastIndex = name.lastIndexOf(" ");
    let fname = name.substring(0, lastIndex);
    if(fname){
        return fname;
    } else{
        return name;
    }
}
  
/**
   * set the flag into the browser localstorage to check if user have Organization or not
   * @flag : Boolean
   * @returns : Void
*/
function setIsUserHaveOrg(flag){
    localStorage.setItem('isUserHaveOrg', flag);
}
  
/**
   * get the flag from browser localstorage if user have any organization
   * @void
   * @returns : Boolean
*/
function getIsUserHaveOrg(){
    return (localStorage.getItem('isUserHaveOrg') === 'true');
}

